var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"d-sm-flex flex-column align-center justify-space-between"},[_c('div',{staticClass:"ml-3 d-flex justify-space-between w-100"},[_c('div',[_c('h2',{staticClass:"font-weight-regular title"},[_vm._v(" Lista de notas ")]),(_vm.notes)?_c('h6',{staticClass:"body-2 mb-1 subtitle-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.totalCounts)+" notas ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('p',{staticClass:"pa-0 ma-0 unselect"},[_vm._v("Ordenar por")]),_c('TheNotesFilter',{on:{"applyFilter":_vm.applyFilter}})],1)]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headerArray,"items":_vm.notes,"options":_vm.options,"page":_vm.filters.page,"server-items-length":_vm.totalCounts,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)}},scopedSlots:_vm._u([{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.titulo || '-')+" ")])])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getDateFormat(item.created) || '-')+" ")])])])]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getDateFormat(item.modified) || '-')+" ")])])])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around",staticStyle:{"cursor":"pointer"}},[_c('v-btn',{staticClass:"btn mr-1",staticStyle:{"color":"#009EFB"},attrs:{"color":"#FFF","depressed":""},on:{"click":function($event){return _vm.goToSpecificNote(item.id)}}},[_vm._v(" Editar ")]),_c('v-btn',{staticClass:"btn",staticStyle:{"color":"#FF6E6E"},attrs:{"color":"#FFF","depressed":""},on:{"click":function($event){return _vm.doDeleteNote(item.id)}}},[_vm._v(" Eliminar ")])],1)]}},{key:"footer",fn:function(slotProps){return [_c('div',{staticClass:"pagination d-flex justify-space-between ml-auto mr-0 mt-5"},[_c('p',{staticClass:"ma-auto"},[_vm._v(_vm._s(slotProps.props.pagination.page)+" de "+_vm._s(slotProps.props.pagination.pageCount))]),_c('div',[_c('v-btn',{attrs:{"icon":"","disabled":slotProps.props.pagination.page === 1},on:{"click":function($event){_vm.filters.page--}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.next === null},on:{"click":function($event){_vm.filters.page++}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)])]}}])}),_c('DeleteModal',{attrs:{"noteId":_vm.noteIdToDelete,"references":_vm.references},on:{"closeDeleteNoteModal":function($event){_vm.deleteNote=false}},model:{value:(_vm.deleteNote),callback:function ($$v) {_vm.deleteNote=$$v},expression:"deleteNote"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }