<template>
  <v-dialog
    v-bind="$attrs"
    class="modal rounded-xl ma-auto"
    :max-width="$vuetify.breakpoint.smAndDown ? '95%': '40%'"
    :min-height="$vuetify.breakpoint.smAndDown ? '350': '100%'"
    persistent
    style="border: 1px solid red !important;"
    v-on="$listeners"
  >
    <v-card
      class="rounded-xl py-7 mx-auto"
      width="100%"
    >
        <p
          class="modal__title text-center pa-0 ma-0 mx-15 unselect"
          :style="references ? '': 'color: black !important;'"
        >
          Esta nota ha sido referenciado en otras {{ references.length }} notas, estas seguro de que quieres eliminarla?
        </p>
        <v-card-text
          class="mb-0 mt-15 d-flex flex-column align-center"
        >
          <p
            v-for="(note, index) in references"
            :key="index"
            class="modal__list text-start unselect pb-4"
          >
            {{ note.titulo }}
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-5 pb-0">
          <v-btn
            type="submit"
            class="modal__btn modal__btn--cancel mr-1"
            @click="$emit('closeDeleteNoteModal')"
          >
            Cancelar
          </v-btn>
          <v-btn
            type="submit"
            class="modal__btn modal__btn--delete ml-1"
            :disabled="references.length!==0"
            @click="deleteNote(noteId)"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    isVisible: false,
    counter: 0
  }),
  props: {
    startTime: {
      type: Boolean,
      default: false
    },
    references: {
      type: Array,
      default: () => []
    },
    noteId: {
      type: Number,
      default: null
    }
  },
  methods: {
    async deleteNote (noteId) {
      try {
        await axios.delete(`api/admin/notas/notas/${noteId}/`)
        this.$emit('closeDeleteNoteModal')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.modal {
  &__list {
    font-size: 14px;
    line-height: 16px;
    color: #647485;
    border-bottom: 0.5px solid #D3D3D3;
    width: 90% !important;
  }
  &__border {
    border-radius: 40px !important;
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FC4B6C;
  }
  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;
    font-weight: normal!important;
    &--cancel {
      background: #FFFFFF;
      color: #000000;
    }
    &--delete {
      background: #009EFB !important;
      color: #FFF;
    }
  }
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
