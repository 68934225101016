<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex flex-column align-center justify-space-between">
        <div class="ml-3 d-flex justify-space-between w-100">
          <div>
            <h2 class="font-weight-regular title">
              Lista de notas
            </h2>
            <h6
              v-if="notes" class="body-2 mb-1 subtitle-2 font-weight-light"
            >
              {{ totalCounts }} notas
            </h6>
          </div>
          <div class="d-flex justify-center align-center">
            <p class="pa-0 ma-0 unselect">Ordenar por</p>
            <TheNotesFilter @applyFilter="applyFilter"/>
          </div>
        </div>
        <v-spacer></v-spacer>
      </div>
      <!-- Table -->
      <v-data-table
        :headers="headerArray"
        :items="notes"
        :options.sync="options"
        :page.sync="filters.page"
        :server-items-length="totalCounts"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:item.titulo="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.titulo || '-' }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.created="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ getDateFormat(item.created) || '-' }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.modified="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ getDateFormat(item.modified) || '-' }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.accion="{ item }">
          <div class="d-flex justify-space-around" style="cursor: pointer;">
            <v-btn
              class="btn mr-1"
              color="#FFF"
              style="color: #009EFB;"
              depressed
              @click="goToSpecificNote(item.id)"
            >
              Editar
            </v-btn>
            <v-btn
              class="btn"
              color="#FFF"
              style="color: #FF6E6E;"
              depressed
              @click="doDeleteNote(item.id)"
            >
              Eliminar
            </v-btn>
          </div>
        </template>
        <template v-slot:footer="slotProps">
          <div class="pagination d-flex justify-space-between ml-auto mr-0 mt-5">
            <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ slotProps.props.pagination.pageCount }}</p>
            <div>
              <v-btn icon @click="filters.page--" :disabled="slotProps.props.pagination.page === 1">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn icon @click="filters.page++" :disabled="next === null">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      <DeleteModal
        v-model="deleteNote"
        :noteId="noteIdToDelete"
        :references="references"
        @closeDeleteNoteModal="deleteNote=false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DeleteModal from '@/components/dashboardComponents/DeleteModal.vue'
import TheNotesFilter from '@/components/dashboardComponents/TheNotesFilter.vue'

export default {
  components: { DeleteModal, TheNotesFilter },
  data: () => ({
    loading: false,
    headerArray: [
      { text: 'Titulo de la nota', value: 'titulo', sortable: true, align: 'start' },
      { text: 'Fecha de creación', value: 'created', sortable: true, align: 'start' },
      { text: 'Ult. modificación', value: 'modified', sortable: true, align: 'start' },
      { text: '', value: 'accion', sortable: false, align: 'start' }
    ],
    notes: [],
    deleteNote: false,
    fechaInicio: moment(new Date()).format('DD/MM/YYYY'),
    fechaUltimaModificacion: moment(new Date()).format('DD/MM/YYYY'),
    filters: {
      page: 1
    },
    next: null,
    references: [],
    totalCounts: 0,
    options: {},
    noteIdToDelete: null
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  async mounted () {
    await this.getDataFromApi()
  },
  methods: {
    goToSpecificNote (index) {
      this.$router.push({ name: 'UpdateNote', params: { id: index } })
    },
    applyFilter () {
      alert('asdnasdasd')
    },
    getDateFormat (date) {
      return moment(new Date(date)).format('DD/MM/YYYY')
    },
    async doDeleteNote (noteId) {
      this.deleteNote = true
      this.noteIdToDelete = noteId
      try {
        const response = await axios.get(`api/admin/notas/notas/${noteId}/referencias/`)
        this.references = response.data
      } catch (e) {
        console.log(e)
      }
    },
    async getDataFromApi () {
      this.loading = true
      await this.fetchNotesTable().then(data => {
        this.notes = data.items
        this.loading = false
      })
    },
    fetchNotesTable () {
      /* eslint-disable */
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, itemsPerPage } = this.options
        const notes = await this.fetchNotes(this.filters.page)
        let items = notes.results
        this.next = notes.next
        this.totalCounts = notes.count
        const total = items.length

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]]
            const sortB = b[sortBy[0]]

            if (sortDesc[0]) {
              if (sortA < sortB) return 1
              if (sortA > sortB) return -1
              return 0
            } else {
              if (sortA < sortB) return -1
              if (sortA > sortB) return 1
              return 0
            }
          })
        }

        setTimeout(() => {
          resolve({
            items,
            total
          })
        }, 1000)
      })
    },
    async fetchNotes (id) {
      const response = await axios.get(`api/admin/notas/notas/?page=${id}`)
      return response.data
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  border: 1px solid #CED4DA !important;
  text-transform: none !important;
  box-shadow: none !important;
}
</style>
